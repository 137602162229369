import { combineEpics, Epic } from 'redux-observable'
import { of, Subject } from 'rxjs'
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  merge,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import {
  ActionType,
  createAsyncAction,
  createCustomAction,
  createReducer,
  getType,
  isActionOf,
} from 'typesafe-actions'
import { printHtmlPage } from '../../helpers/file'
import XHRFactory from '../../helpers/XHRFactory'
import { id } from '../../models/base'
import { MAX_PARALLEL_UPLOAD } from '../../models/constants'
import { ISuiquiFile } from '../../models/suiquiFile'
import { IEvent, IList, Signature } from '../../models/template'
import {
  ETicketFileLabel,
  ITicket,
  ITicketFilesUpload,
  ITicketNotificationBell,
} from '../../models/ticket'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import {
  createPublicTicket,
  fetchPublicTemplate,
} from '../public_ticket/actions'
import { RootAction } from './types'

const initialState = {
  key: undefined,
  tickets: [],
  isDone: false,
  listKey: undefined,
  pFiles: {},
  doneCount: 0,
  fix_attachment: { files: [], photos: [] },
  fix_after: { files: [], photos: [] },
  fix_in_progress: { files: [], photos: [] },
  fix_before: { files: [], photos: [] },
  signatures: [], // 工單的簽核欄位
  currentSignature: undefined, // 正在檢視的簽核欄位
  isLoadingCurrentSignature: false, // 是否在讀取正在檢視的簽核欄位
  quickSignSignatureValue: undefined, // 用來快速簽名的簽名
  toastMessage: undefined,
}

export const fetchTicketList = createAsyncAction(
  'FETCH_TICKET_LIST_REQUEST',
  'FETCH_TICKET_LIST_SUCCESS',
  'FETCH_TICKET_LIST_FAILURE',
  'FETCH_TICKET_LIST_CANCEL'
)<
  Partial<ITicket & IList> & Pick<IList, 'key' | 'cursor'>,
  Partial<ITicket & IList>,
  Error,
  undefined
>()
export const fetchTicketListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchTicketList.request)),
    switchMap((action) =>
      ticketAPI
        .list({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event) {
              if (event.target) {
                // @ts-ignore
                event.target.complete()
              } else if (event.detail) {
                // @ts-ignore
                event.detail.complete()
              }
            }
          })
        )
        .pipe(
          map((data) =>
            fetchTicketList.success({ ...data.response, status: data.status })
          ),
          catchError((message: Error) => of(fetchTicketList.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(fetchTicketList.cancel))))
        )
    )
  )

export const fetchTicket = createAsyncAction(
  'FETCH_TICKET_REQUEST',
  'FETCH_TICKET_SUCCESS',
  'FETCH_TICKET_FAILURE',
  'FETCH_TICKET_CANCEL'
)<Partial<ITicket & IEvent> & Pick<ITicket, 'key'>, ITicket, Error, undefined>()

export const fetchTicketEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchTicket.request)),
    switchMap((action) =>
      ticketAPI
        .get({ token: state$.value.authDuck.token, params: action.payload })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event) {
              if (event.target) {
                // @ts-ignore
                event.target.complete()
              } else if (event.detail) {
                // @ts-ignore
                event.detail.complete()
              }
            }
          })
        )
        .pipe(
          map((data) =>
            fetchTicket.success({ ...data.response, status: data.status })
          ),
          catchError((message: Error) => of(fetchTicket.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(fetchTicket.cancel))))
        )
    )
  )

export const fetchPublicTicket = createAsyncAction(
  'FETCH_PUBLIC_TICKET_REQUEST',
  'FETCH_PUBLIC_TICKET_SUCCESS',
  'FETCH_PUBLIC_TICKET_FAILURE',
  'FETCH_PUBLIC_TICKET_CANCEL'
)<Partial<ITicket & IEvent> & Pick<ITicket, 'key'>, ITicket, Error, undefined>()

export const fetchPublicTicketEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchPublicTicket.request)),
    switchMap((action) =>
      ticketAPI
        .getPublic({ params: action.payload })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event) {
              if (event.target) {
                // @ts-ignore
                event.target.complete()
              } else if (event.detail) {
                // @ts-ignore
                event.detail.complete()
              }
            }
          })
        )
        .pipe(
          map((data) => fetchPublicTicket.success(data.response)),
          catchError((message: Error) =>
            of(fetchPublicTicket.failure(message))
          ),
          takeUntil(action$.pipe(filter(isActionOf(fetchPublicTicket.cancel))))
        )
    )
  )

export const createTicket = createAsyncAction(
  'CREATE_TICKET_REQUEST',
  'CREATE_TICKET_SUCCESS',
  'CREATE_TICKET_FAILURE',
  'CREATE_TICKET_CANCEL'
)<Partial<ITicket>, ITicket, Error, undefined>()

export const createTicketEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(createTicket.request)),
    switchMap((action) =>
      ticketAPI
        .create({ token: state$.value.authDuck.token, params: action.payload })
        .pipe(
          map((data) => createTicket.success(data.response)),
          catchError((message: Error) => of(createTicket.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(createTicket.cancel))))
        )
    )
  )

export const updateTicket = createAsyncAction(
  'UPDATE_TICKET_REQUEST',
  'UPDATE_TICKET_SUCCESS',
  'UPDATE_TICKET_FAILURE',
  'UPDATE_TICKET_CANCEL'
)<Partial<ITicket> & Pick<ITicket, 'key'>, ITicket, Error, undefined>()

export const updateTicketEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(updateTicket.request)),
    switchMap((action) =>
      ticketAPI
        .update({ token: state$.value.authDuck.token, params: action.payload })
        .pipe(
          map((data) => updateTicket.success(data.response)),
          catchError((message: Error) => of(updateTicket.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(updateTicket.cancel))))
        )
    )
  )

export const updateTicketStatus = createAsyncAction(
  'UPDATE_TICKET_STATUS_REQUEST',
  'UPDATE_TICKET_STATUS_SUCCESS',
  'UPDATE_TICKET_STATUS_FAILURE',
  'UPDATE_TICKET_STATUS_CANCEL'
)<
  Partial<ITicket> & Pick<ITicket, 'key' | 'ticket_status'>,
  ITicket,
  Error,
  undefined
>()

export const updateTicketStatusEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(updateTicketStatus.request)),
    switchMap((action) =>
      ticketAPI
        .updateStatus({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          map((data) => updateTicketStatus.success(data.response)),
          catchError((message: Error) =>
            of(updateTicketStatus.failure(message))
          ),
          takeUntil(action$.pipe(filter(isActionOf(updateTicketStatus.cancel))))
        )
    )
  )

export const printTicket = createAsyncAction(
  'PRINT_TICKET_REQUEST',
  'PRINT_TICKET_SUCCESS',
  'PRINT_TICKET_FAILURE',
  'PRINT_TICKET_CANCEL'
)<
  {
    key: id
    filename: string
    include_fields: {
      system: boolean
      customer: boolean
      custom: boolean
      internal_custom: boolean
      photos: boolean
      signatures: boolean
    }
  },
  { data: string; filename: string; status: number },
  Error,
  undefined
>()

export const printTicketEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(printTicket.request)),
    switchMap((action) =>
      ticketAPI
        .print({
          token: state$.value.authDuck.token,
          params: { key: action.payload.key, include_fields: action.payload.include_fields },
        })
        .pipe(
          map((data) =>
            printTicket.success({
              data: data.response,
              filename: action.payload.filename,
              status: data.status,
            })
          ),
          catchError((message: Error) => of(printTicket.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(printTicket.cancel))))
        )
    )
  )

export const uploadFilesCancelAll = createCustomAction(
  'UPLOAD_FILES_CANCEL_ALL',
  (payload) => {
    return {
      payload,
    }
  }
)

export const uploadFilesFailed = createCustomAction(
  'UPLOAD_FILES_FAILED',
  (payload) => {
    return {
      payload,
    }
  }
)

export const uploadFileProgress = createCustomAction(
  'UPLOAD_FILE_PROGRESS',
  (payload) => {
    return {
      payload: {
        hash: payload.hash,
        percentage: payload.percentage,
      },
    }
  }
)

export const uploadFiles = createAsyncAction(
  'UPLOAD_FILES_REQUEST',
  'UPLOAD_FILES_SUCCESS',
  'UPLOAD_FILES_FAILURE',
  'UPLOAD_FILES_CANCEL'
)<
  Partial<ITicketFilesUpload> & { key: id; label: ETicketFileLabel },
  ITicketFilesUpload,
  Partial<ITicketFilesUpload> & { message: Error },
  { hash?: number }
>()

export const uploadFilesEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { uploadAPI, ticketAPI }) => {
  return action$.pipe(
    filter(isActionOf(uploadFiles.request)),
    mergeMap((action) => {
      const { hash } = action.payload
      const progressSubscriber$ = new Subject()
      const request$ = ticketAPI
        .getUploadUrl({
          token: state$.value.authDuck.token,
          key: action.payload.key,
        })
        .pipe(
          mergeMap((uploadUrl) => {
            const xhr = XHRFactory.getInstance()
            const getRequest$ = () => xhr
            return uploadAPI
              .exec({
                token: state$.value.authDuck.token,
                uploadUrl,
                params: {
                  label: action.payload.label,
                },
                files: action.payload.files,
                filenames: action.payload.filenames,
                createXHR: getRequest$,
                progressSubscriber: progressSubscriber$,
              })
              .pipe(
                concatMap((data) => {
                  return of(
                    uploadFiles.success({
                      ...data.response,
                      hash: action.payload.hash,
                      hashFunc: action.payload.hashFunc,
                      files: action.payload.files,
                      doneCount: !!action.payload.files
                        ? action.payload.files?.length
                        : undefined,
                    })
                  )
                }),
                catchError((message: Error) =>
                  of(
                    uploadFiles.failure({
                      ...action.payload,
                      message,
                    })
                  )
                ),
                finalize(() => {
                  XHRFactory.release(xhr)
                }),
                takeUntil(
                  !!action.payload.files
                    ? action$.pipe(filter(isActionOf(uploadFiles.cancel)))
                    : action$.pipe(
                        filter(isActionOf(uploadFiles.cancel)),
                        filter((action) => action.payload.hash === hash)
                      )
                ),
                takeUntil(
                  action$.pipe(filter((action) => action.payload.hash === hash))
                ),
                takeUntil(
                  action$.pipe(filter(isActionOf(uploadFilesCancelAll)))
                ),
                takeUntil(action$.pipe(filter(isActionOf(uploadFilesFailed))))
              )
          }, MAX_PARALLEL_UPLOAD)
        )

      return !!action.payload.files
        ? progressSubscriber$.pipe(
            map((e: any) => ({ percentage: (e.loaded / e.total) * 100 || 0 })),
            concatMap((data) =>
              (action.payload.files || []).map((_file, index) =>
                uploadFileProgress({
                  hash: action.payload.hashFunc
                    ? action.payload.hashFunc(_file, index)
                    : 0,
                  percentage: data.percentage,
                })
              )
            ),
            merge(request$),
            catchError((message: Error) =>
              of(
                uploadFiles.failure({
                  ...action.payload,
                  message,
                })
              )
            )
          )
        : progressSubscriber$.pipe(
            map((e: any) => ({ percentage: (e.loaded / e.total) * 100 || 0 })),
            map((data) =>
              uploadFileProgress({
                hash: action.payload.hash,
                percentage: data.percentage,
              })
            ),
            merge(request$),
            catchError((message: Error) =>
              of(
                uploadFiles.failure({
                  ...action.payload,
                  message,
                })
              )
            )
          )
    }, MAX_PARALLEL_UPLOAD)
  )
}

export const removeTicket = createAsyncAction(
  'REMOVE_TICKET_REQUEST',
  'REMOVE_TICKET_SUCCESS',
  'REMOVE_TICKET_FAILURE',
  'REMOVE_TICKET_CANCEL'
)<Partial<ITicket> & Pick<ITicket, 'key'>, ITicket, Error, undefined>()

export const removeTicketEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(removeTicket.request)),
    switchMap((action) =>
      ticketAPI
        .remove({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          map((data) => removeTicket.success(data.response)),
          catchError((message: Error) => of(removeTicket.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(removeTicket.cancel))))
        )
    )
  )

export const updateFile = createAsyncAction(
  'UPDATE_FILE_REQUEST',
  'UPDATE_FILE_SUCCESS',
  'UPDATE_FILE_FAILURE',
  'UPDATE_FILE_CANCEL'
)<{ key: id; memo: string }, ITicket, Error, undefined>()

export const updateFileEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(updateFile.request)),
    switchMap((action) =>
      ticketAPI
        .updateFile({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          map((data) => updateFile.success(data.response)),
          catchError((message: Error) => of(updateFile.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(updateFile.cancel))))
        )
    )
  )

export const removeFiles = createAsyncAction(
  'REMOVE_FILES_REQUEST',
  'REMOVE_FILES_SUCCESS',
  'REMOVE_FILES_FAILURE',
  'REMOVE_FILES_CANCEL'
)<{ files: File[] }, ITicket, Error, undefined>()

export const removeFilesEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(removeFiles.request)),
    switchMap((action) =>
      ticketAPI
        .removeFiles({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          map((data) => removeFiles.success(data.response)),
          catchError((message: Error) => of(removeFiles.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(removeFiles.cancel))))
        )
    )
  )

// 取得完整的簽核資訊，包含簽名的內容
export const getSignature = createAsyncAction(
  'GET_SIGNATURE_REQUEST',
  'GET_SIGNATURE_SUCCESS',
  'GET_SIGNATURE_FAILURE',
  'GET_SIGNATURE_CANCEL'
)<{ key: id }, Signature, Error, undefined>()
export const getSignatureEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { signatureAPI }) =>
  action$.pipe(
    filter(isActionOf(getSignature.request)),
    switchMap((action) =>
      signatureAPI
        .getSignature({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          map((data) =>
            getSignature.success({
              ...data.response,
              status: data.status,
            })
          ),
          catchError((message: Error) => of(getSignature.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(getSignature.cancel))))
        )
    )
  )

// 在簽核欄位上簽名
export const signSignature = createAsyncAction(
  'SIGN_SIGNATURE_REQUEST',
  'SIGN_SIGNATURE_SUCCESS',
  'SIGN_SIGNATURE_FAILURE',
  'SIGN_SIGNATURE_CANCEL'
)<{ key: id; value: string }, Signature, Error, undefined>()
export const signSignatureEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { signatureAPI }) =>
  action$.pipe(
    filter(isActionOf(signSignature.request)),
    switchMap((action) =>
      signatureAPI
        .signSignature({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          map((data) =>
            signSignature.success({
              ...data.response,
              status: data.status,
            })
          ),
          catchError((message: Error) => of(signSignature.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(signSignature.cancel))))
        )
    )
  )

// 刪除簽核欄位上的簽名
export const unsignSignature = createAsyncAction(
  'UNSIGN_SIGNATURE_REQUEST',
  'UNSIGN_SIGNATURE_SUCCESS',
  'UNSIGN_SIGNATURE_FAILURE',
  'UNSIGN_SIGNATURE_CANCEL'
)<{ key: id }, Signature, Error, undefined>()
export const unsignSignatureEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { signatureAPI }) =>
  action$.pipe(
    filter(isActionOf(unsignSignature.request)),
    switchMap((action) =>
      signatureAPI
        .unsignSignature({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          map((data) =>
            unsignSignature.success({
              ...data.response,
              status: data.status,
            })
          ),
          catchError((message: Error) => of(unsignSignature.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(unsignSignature.cancel))))
        )
    )
  )

// 快速簽名（取得使用者以前的簽名）
export const getQuickSignSignature = createAsyncAction(
  'GET_QUICK_SIGN_SIGNATURE_REQUEST',
  'GET_QUICK_SIGN_SIGNATURE_SUCCESS',
  'GET_QUICK_SIGN_SIGNATURE_FAILURE',
  'GET_QUICK_SIGN_SIGNATURE_CANCEL'
)<undefined, Signature, Error, undefined>()
export const getQuickSignSignatureEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { userAPI }) =>
  action$.pipe(
    filter(isActionOf(getQuickSignSignature.request)),
    switchMap((action) =>
      userAPI
        .getQuickSignature({
          token: state$.value.authDuck.token,
        })
        .pipe(
          map((data) =>
            getQuickSignSignature.success({
              ...data.response,
              status: data.status,
            })
          ),
          catchError((message: Error) =>
            of(getQuickSignSignature.failure(message))
          ),
          takeUntil(
            action$.pipe(filter(isActionOf(getQuickSignSignature.cancel)))
          )
        )
    )
  )

// 更新節點提醒
export const updateTicketReminder = createAsyncAction(
  'UPDATE_TICKET_REMINDER_REQUEST',
  'UPDATE_TICKET_REMINDER_SUCCESS',
  'UPDATE_TICKET_REMINDER_FAILURE',
  'UPDATE_TICKET_REMINDER_CANCEL'
)<
  { key: id; notification_bells: ITicketNotificationBell[] },
  { ticket: ITicket },
  Error,
  undefined
>()
export const updateTicketReminderEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(updateTicketReminder.request)),
    switchMap((action) =>
      ticketAPI
        .updateTicketReminder({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          map((data) =>
            updateTicketReminder.success({
              ...data.response,
              status: data.status,
            })
          ),
          catchError((message: Error) =>
            of(updateTicketReminder.failure(message))
          ),
          takeUntil(
            action$.pipe(filter(isActionOf(updateTicketReminder.cancel)))
          )
        )
    )
  )

// 清除 state 中的快速簽名
export const clearQuickSign = createCustomAction('CLEAR_QUICK_SIGN')

// 清除 state 中的 currentSignature
export const clearCurrentSignature = createCustomAction(
  'CLEAR_CURRENT_SIGNATURE'
)

// 清除 state 中的 toastMessage
export const clearToastMessage = createCustomAction('CLEAR_TOAST_MESSAGE')

const requestMultiWithPayload = ({ hashFunc, files }: any, pFiles: any) => {
  if (hashFunc && files) {
    files.forEach((file: any, index: number) => {
      pFiles[hashFunc(file, index)] = {}
    })
  } else {
    pFiles = {}
  }

  return pFiles
}

const doneMultiWithPayload = ({ hashFunc, files }: any, pFiles: any) => {
  if (hashFunc && files) {
    files.forEach((file: any, index: number) => {
      pFiles[hashFunc(file, index)] = {
        ...pFiles[hashFunc(file, index)],
        isDone: true,
        percentage: 100,
        isAllowProcessing: false,
      }
    })
  } else {
    pFiles = {}
  }

  return pFiles
}

const doneWithPayload = ({ hash }: any, pFiles: any) => {
  if (hash) {
    pFiles[hash] = {
      ...pFiles[hash],
      isDone: true,
      percentage: 100,
      isAllowProcessing: false,
    }
  } else {
    pFiles = {}
  }

  return pFiles
}

const progressWithPayload = ({ hash, percentage }: any, pFiles: any) => {
  pFiles[hash] = {
    ...pFiles[hash],
    isDone: false,
    isCancel: false,
    percentage: percentage || pFiles[hash].percentage,
    isStartProcessing: false,
    isAllowProcessing: true,
  }

  return pFiles
}

const requestWithPayload = ({ hash }: any, pFiles: any) => {
  pFiles[hash] = {
    isDone: false,
    isCancel: false,
    percentage: NaN,
    isStartProcessing: true,
  }

  return pFiles
}

const resetWithPayload = ({ hash }: any, pFiles: any) => {
  if (pFiles && pFiles[hash]) {
    pFiles[hash] = {
      ...pFiles[hash],
      isDone: false,
      isCancel: true,
      isPending: false,
      percentage: NaN,
      isAllowProcessing: false,
      isFailed: false,
    }
  } else {
    pFiles = {}
  }
  return pFiles
}

const failureMultiWithPayload = ({ hashFunc, files }: any, pFiles: any) => {
  if (hashFunc && files) {
    files.forEach((file: any, index: number) => {
      pFiles[hashFunc(file, index)] = {
        ...pFiles[hashFunc(file, index)],
        isDone: false,
        isCancel: true,
        isPending: false,
        percentage: NaN,
        isAllowProcessing: false,
        isFailed: true,
      }
    })
  } else {
    pFiles = {}
  }

  return pFiles
}

const failureWithPayload = ({ hash }: any, pFiles: any) => {
  if (pFiles[hash]) {
    pFiles[hash] = {
      ...pFiles[hash],
      isDone: false,
      isCancel: true,
      isPending: false,
      percentage: NaN,
      isAllowProcessing: false,
      isFailed: true,
    }
  } else {
    pFiles = {}
  }
  return pFiles
}

export const ticketReducer = createReducer(initialState)
  .handleAction(
    getType(fetchTicketList.request),
    (state: any, { payload }: any) => ({
      ...initialState,
      tickets: state.tickets,
      cursor: payload.cursor,
      name: state.name,
    })
  )
  .handleAction(
    getType(fetchTicketList.success),
    (state: any, { payload }: any) => ({
      ...initialState,
      ...payload,
      tickets: [].concat(state.tickets || [], payload.tickets || []),
      error: null,
      isDone: true,
      listKey: payload.key,
      list_function_list: payload.function_list,
      listState: payload,
    })
  )
  .handleAction(
    getType(fetchTicketList.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      isDone: true,
      listKey: undefined,
    })
  )
  .handleAction(
    getType(fetchTicketList.cancel),
    (state: any, { payload }: any) => ({
      ...initialState,
      isDone: false,
    })
  )
  .handleAction(
    getType(fetchTicket.request),
    (state: any, { payload }: any) => ({
      ...initialState,
      tickets: state.tickets,
      cursor: state.cursor,
      isDone: false,
      listKey: state.listKey,
      key: state.listKey ? undefined : state.key,
      list_function_list: state.list_function_list,
      listState: state.listState,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(fetchTicket.success),
    (state: any, { payload }: any) => ({
      ...initialState,
      tickets: state.tickets,
      cursor: state.cursor,
      listKey: payload.template_key ?? state.listKey,
      ...payload,
      error: null,
      isDone: true,
      list_function_list: state.list_function_list,
      listState: state.listState,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(fetchTicket.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchTicket.cancel),
    (state: any, { payload }: any) => ({
      ...initialState,
      ...state.listState,
      key: state.key,
      listKey: state.listKey,
      tickets: state.tickets,
      cursor: state.cursor,
      isDone: state.isDone,
      function_list: state.list_function_list || state.function_list,
      list_function_list: state.list_function_list,
      listState: state.listState,
      error: null,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(fetchPublicTicket.request),
    (state: any, { payload }: any) => ({
      ...initialState,
    })
  )
  .handleAction(
    getType(fetchPublicTicket.success),
    (state: any, { payload }: any) => ({
      ...payload,
      isDone: true,
      error: null,
    })
  )
  .handleAction(
    getType(fetchPublicTicket.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchPublicTicket.cancel),
    (state: any, { payload }: any) => ({
      ...initialState,
    })
  )
  .handleAction(
    getType(createTicket.request),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      created: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(createTicket.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      created: true,
      shouldReload: true,
    })
  )
  .handleAction(
    getType(createTicket.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      created: false,
    })
  )
  .handleAction(
    getType(createTicket.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      error: null,
      created: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(updateTicket.request),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      updated: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(updateTicket.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      updated: true,
      shouldReload: true,
    })
  )
  .handleAction(
    getType(updateTicket.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      updated: false,
    })
  )
  .handleAction(
    getType(updateTicket.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      error: null,
      updated: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(updateTicketStatus.request),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      updated: undefined,
      statusUpdated: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(updateTicketStatus.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      updated: true,
      statusUpdated: true,
      shouldReload: true,
    })
  )
  .handleAction(
    getType(updateTicketStatus.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      updated: false,
      statusUpdated: false,
    })
  )
  .handleAction(
    getType(updateTicketStatus.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      error: null,
      updated: undefined,
      statusUpdated: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(removeTicket.request),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      removed: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(removeTicket.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      removed: true,
      shouldReload: true,
      key: undefined,
    })
  )
  .handleAction(
    getType(removeTicket.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      removed: false,
    })
  )
  .handleAction(
    getType(removeTicket.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      error: null,
      removed: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(printTicket.success),
    (
      state: any,
      {
        payload: { data, filename },
      }: { payload: { data: string; filename: string } }
    ) => {
      printHtmlPage(data, filename)
      return {
        ...state,
      }
    }
  )
  .handleAction(
    getType(uploadFileProgress),
    (state: any, { payload }: any) => ({
      ...state,
      pFiles: progressWithPayload(payload, state.pFiles),
      doneCount: state.doneCount,
    })
  )
  .handleAction(
    getType(uploadFilesCancelAll),
    (state: any, { payload }: any) => ({
      ...state,
      pFiles: resetWithPayload(payload, null),
      doneCount: null,
      ...payload,
      error: null,
    })
  )
  .handleAction(
    getType(uploadFiles.request),
    (state: any, { payload }: any) => ({
      ...state,
      doneCount: state.doneCount,
      pFiles: payload.files
        ? requestMultiWithPayload(payload, state.pFiles)
        : requestWithPayload(payload, state.pFiles),
      uploaded: undefined,
    })
  )
  .handleAction(
    getType(uploadFiles.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      pFiles: payload.files
        ? doneMultiWithPayload(payload, state.pFiles)
        : doneWithPayload(payload, state.pFiles),
      doneCount: payload.files ? payload.doneCount : state.doneCount + 1,
      error: null,
      uploaded: true,
      shouldReload: true,
    })
  )
  .handleAction(
    getType(uploadFiles.failure),
    (state: any, { payload }: any) => ({
      ...state,
      pFiles: payload.files
        ? failureMultiWithPayload(payload, state.pFiles)
        : failureWithPayload(payload, state.pFiles),
      doneCount: state.doneCount,
      error: payload,
      uploaded: false,
    })
  )
  .handleAction(
    getType(uploadFiles.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      pFiles: resetWithPayload(payload, state.pFiles),
      error: null,
      uploaded: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(removeFiles.request),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      filesRemoved: undefined,
    })
  )
  .handleAction(
    getType(removeFiles.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      filesRemoved: true,
      shouldReload: true,
    })
  )
  .handleAction(
    getType(removeFiles.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      filesRemoved: false,
    })
  )
  .handleAction(
    getType(removeFiles.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      error: null,
      filesRemoved: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(fetchPublicTemplate.success),
    (state: any, { payload }: any) => ({
      ...initialState,
      ...payload,
      listKey: payload.key,
      error: null,
      isDone: true,
    })
  )
  .handleAction(
    getType(createTicket.request),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      created: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(createPublicTicket.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      created: true,
      shouldReload: true,
    })
  )
  .handleAction(
    getType(createPublicTicket.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      created: false,
    })
  )
  .handleAction(
    getType(createPublicTicket.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      error: null,
      created: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(updateFile.request),
    (state: any, { payload }: any) => ({
      ...state,
      fileUpdated: undefined,
    })
  )
  .handleAction(getType(updateFile.success), (state: any, { payload }: any) => {
    // 找到要更新的檔案是在哪一個標籤底下，並替換成更新後的檔案
    const { fix_attachment, fix_after, fix_in_progress, fix_before } = state
    for (const label of [
      fix_attachment,
      fix_after,
      fix_in_progress,
      fix_before,
    ]) {
      let index = label.files.findIndex(
        (file: ISuiquiFile) => file.key === payload.key
      )
      if (index >= 0) {
        label.files[index] = payload
      }
      index = label.photos.findIndex(
        (file: ISuiquiFile) => file.key === payload.key
      )
      if (index >= 0) {
        label.photos[index] = payload
      }
    }
    return {
      ...state,
      fix_attachment,
      fix_after,
      fix_in_progress,
      fix_before,
      fileUpdated: true,
      error: null,
    }
  })
  .handleAction(getType(getSignature.request), (state: any) => ({
    ...state,
    currentSignature: null,
    isLoadingCurrentSignature: true,
  }))
  .handleAction(
    getType(getSignature.success),
    (
      state: any,
      { payload: { signature } }: { payload: { signature: Signature } }
    ) => ({
      ...state,
      currentSignature: signature,
      isLoadingCurrentSignature: false,
    })
  )
  .handleAction(getType(getSignature.failure), (state: any) => ({
    ...state,
    isLoadingCurrentSignature: false,
  }))
  .handleAction(getType(clearCurrentSignature), (state: any) => ({
    ...state,
    currentSignature: null,
    isLoadingCurrentSignature: false,
  }))
  .handleAction(
    getType(signSignature.success),
    (
      state: any,
      {
        payload: { signature: modifiedSignature },
      }: { payload: { signature: Signature } }
    ) => {
      const tmpState = { ...state }

      // 找到被修改的簽名
      const modifiedSignatureIndex = tmpState.signatures.findIndex(
        (e: Signature) => e.key === modifiedSignature.key
      )

      // 將其更新成修改後的簽名
      tmpState.signatures[modifiedSignatureIndex] = modifiedSignature
      return {
        ...tmpState,
        currentSignature: modifiedSignature,
        toastMessage: 'Signed successfully.',
      }
    }
  )
  .handleAction(
    getType(unsignSignature.success),
    (
      state: any,
      {
        payload: { signature: modifiedSignature },
      }: { payload: { signature: Signature } }
    ) => {
      const tmpState = { ...state }

      // 找到被修改的簽名
      const modifiedSignatureIndex = tmpState.signatures.findIndex(
        (e: Signature) => e.key === modifiedSignature.key
      )

      // 將其更新成修改後的簽名
      tmpState.signatures[modifiedSignatureIndex] = modifiedSignature
      return {
        ...tmpState,
        currentSignature: modifiedSignature,
        toastMessage: 'Cleared the signature successfully.',
      }
    }
  )
  .handleAction(
    getType(getQuickSignSignature.success),
    (state: any, { payload }: { payload: { signature: string } }) => ({
      ...state,
      quickSignSignatureValue: payload.signature,
    })
  )
  .handleAction(
    getType(updateTicketReminder.success),
    (
      state: any,
      { payload: { ticket } }: ReturnType<typeof updateTicketReminder.success>
    ) => ({
      ...state,
      notification_bells: ticket.notification_bells,
      toastMessage: 'Update reminder successfully',
    })
  )
  .handleAction(getType(clearQuickSign), (state: any) => ({
    ...state,
    quickSignSignatureValue: undefined,
  }))
  .handleAction(getType(clearToastMessage), (state: any) => ({
    ...state,
    toastMessage: undefined,
  }))

export type TicketAction =
  | ActionType<typeof fetchTicketList>
  | ActionType<typeof fetchTicket>
  | ActionType<typeof fetchPublicTicket>
  | ActionType<typeof createTicket>
  | ActionType<typeof updateTicket>
  | ActionType<typeof updateTicketStatus>
  | ActionType<typeof printTicket>
  | ActionType<typeof removeTicket>
  | ActionType<typeof uploadFiles>
  | ActionType<typeof uploadFilesCancelAll>
  | ActionType<typeof uploadFilesFailed>
  | ActionType<typeof uploadFileProgress>
  | ActionType<typeof removeFiles>
  | ActionType<typeof updateFile>
  | ActionType<typeof getSignature>
  | ActionType<typeof signSignature>
  | ActionType<typeof unsignSignature>
  | ActionType<typeof getQuickSignSignature>
  | ActionType<typeof updateTicketReminder>

const ticketEpic = combineEpics<any>(
  fetchTicketListEpic,
  fetchTicketEpic,
  fetchPublicTicketEpic,
  createTicketEpic,
  updateTicketEpic,
  updateTicketStatusEpic,
  printTicketEpic,
  removeTicketEpic,
  uploadFilesEpic,
  removeFilesEpic,
  updateFileEpic,
  getSignatureEpic,
  signSignatureEpic,
  unsignSignatureEpic,
  getQuickSignSignatureEpic,
  updateTicketReminderEpic
)
export default ticketEpic
