import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import { API, JWT, PUBLIC_JSON } from '../helpers/base'
import { id } from '../models/base'
import { ITicketSearch } from '../models/search'
import { IList } from '../models/template'
import { ITicket, ITicketNotificationBell } from '../models/ticket'

export const list = ({
  token,
  params,
}: {
  token: string
  params: { key: id } & Partial<IList> & Partial<ITicket>
}) => {
  return ajax({
    url: API('ticket/list', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })
}

export const getPublic = ({ params }: { params: { key: id } }) =>
  ajax({
    url: API('public/ticket/serv', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: PUBLIC_JSON(),
    responseType: 'json',
  })

export const get = ({
  token,
  params,
}: {
  token: string
  params: { key: id }
}) =>
  ajax({
    url: API('ticket', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const create = ({
  token,
  params,
}: {
  token: string
  params: Partial<ITicket>
}) =>
  ajax({
    url: API('ticket/create', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const update = ({
  token,
  params,
}: {
  token: string
  params: { key: id } & Partial<ITicket>
}) =>
  ajax({
    url: API('ticket/update', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const print = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id
    include_fields: {
      system: boolean
      customer: boolean
      custom: boolean
      internal_custom: boolean
      photos: boolean
      signatures: boolean
    }
  }
}) =>
  ajax({
    url: API('ticket/print', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'html',
  })

export const remove = ({
  token,
  params,
}: {
  token: string
  params: { key: id }
}) =>
  ajax({
    url: API('ticket/delete', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const updateStatus = ({
  token,
  params,
}: {
  token: string
  params: { key: id } & Partial<ITicket>
}) =>
  ajax({
    url: API('ticket/status/update', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const search = ({
  token,
  params,
}: {
  token: string
  params: { key: id } & Partial<ITicketSearch & IList>
}) =>
  ajax({
    url: API('ticket/search', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const getUploadUrl = ({ token, key }: { token: string; key: id }) => {
  return ajax({
    url: API(`ticket/uploadurl`, 'SUPPORT'),
    method: 'POST',
    body: {
      key,
    },
    headers: JWT(token),
    responseType: 'json',
  })
    .pipe(map((res) => res.response))
    .pipe(map((res: { uploadurl: string }) => res.uploadurl))
}

export const removeFiles = ({
  token,
  params,
}: {
  token: string
  params: { files: File[] }
}) =>
  ajax({
    url: API('file/delete', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const getSearchCsv = ({
  token,
  params,
}: {
  token: string
  params: { key: id } & Partial<ITicketSearch & IList>
}) =>
  ajax({
    url: API('ticket/search/csv', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'csv',
  })

// 匯出多個專案工單 csv
export const exportBatchCsv = ({
  token,
  params,
}: {
  token: string
  params: {
    template_keys: id[]
    start_date: string
    end_date: string
  } & Partial<ITicketSearch & IList>
}) =>
  ajax({
    url: API('ticket/csv/batch', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'csv',
  })

// 匯出多個專案工單 csv
export const updateFile = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id
    memo: string
  }
}) =>
  ajax({
    url: API('ticket/file/update', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

// 更新工單的節點提醒
export const updateTicketReminder = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id
    notification_bells: ITicketNotificationBell[]
  }
}) => ajax({
  url: API('ticket/update/bells', 'SUPPORT'),
  method: 'POST',
  body: {
    ...params,
  },
  headers: JWT(token),
  responseType: 'json',
})
