import React, { useCallback, useMemo, useState } from 'react'
import FormTemplateCommonProps from '@data-driven-forms/common/form-template'
import { FormSpy } from '@data-driven-forms/react-form-renderer'
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer'
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api'
import { IonList } from '@ionic/react'
import { default as classnames } from 'classnames'
import { useBeforeunload } from 'react-beforeunload'
import 'react-datepicker/dist/react-datepicker.css'
import { EPreviewFormType } from '../../models/form'
import { ITemplate } from '../../models/template'
import { ITicket } from '../../models/ticket'
import { customComponentMapper, loadUISchema } from '../../helpers/schema'
import Validators from '@data-driven-forms/react-form-renderer/validators'
import { useTranslation } from 'react-i18next'
import './PreviewForm.scss'
import SignatureFields from './SignatureFields'
import { useSelector } from 'react-redux'
import CustomerPhotoFields, { RawFileWithMemo } from './CustomerPhotoFields'
import { hashCode } from '../../helpers/file'
import { IFile } from '../../models/suiquiFile'

interface PreviewFormTemplateProp extends FormTemplateCommonProps {
  /**
   * 當表單有內容變動時，呼叫的 callback
   */
  onChange?: Function

  /**
   * 是否顯示簽名欄位
   */
  isShowSignature: boolean

  /**
   * 是否顯示客戶上傳照片欄位
   * Notes: 此欄位只有在開單時提供客戶上傳照片使用，儲存後會將上傳的內容轉至留言區
   */
  isShowCustomerPhotoFields: boolean
}

/**
 *  實際顯示 Ticket 全部表單欄位的元件
 *
 *  資料流：
 *  所有的資料都是透過 onChange callback 向上傳遞，不會用到 handleSubmit
 */
const PreviewFormTemplate = ({
  formFields,
  onChange,
  isShowSignature,
  isShowCustomerPhotoFields,
}: PreviewFormTemplateProp) => {
  const { handleSubmit, getState } = useFormApi() // 不會用到 handleSubmit
  const { dirty } = getState()
  const signatures = useSelector((state: any) => state.ticket?.signatures) ?? []

  // 關閉視窗時如果有未儲存的輸入會跳出提醒
  useBeforeunload((event: any) => {
    if (dirty) event.preventDefault()
  })

  /**
   * 當下層的 CustomerPhotoFields 的檔案列表有改動時，將其傳給上層的 component
   */
  const onCustomerPhotosChange = (files: RawFileWithMemo[]) => {
    onChange?.(undefined, files)
  }

  return (
    <form onSubmit={handleSubmit}>
      <IonList className="form-container">
        {/* 表單的全部欄位 e.g.「客戶姓名」、「客戶電話」 */}
        {formFields}

        {/* 客戶上傳照片欄位 */}
        {isShowCustomerPhotoFields && (
          <CustomerPhotoFields
            onCustomerPhotosChange={onCustomerPhotosChange}
          />
        )}

        {/* 簽名欄位 */}
        {isShowSignature && signatures.length > 0 && (
          <SignatureFields signatures={signatures} />
        )}

        {/* 監聽使用者輸入的元件（沒有 UI） */}
        <FormSpy
          subscription={{ values: true, valid: true, dirty: true }}
          onChange={(props) => {
            // 當表單的值有更動時，將其傳給上層的 component
            onChange?.({
              ...props,
              formName: 'preview-form',
            })
          }}
        />
      </IonList>
    </form>
  )
}

interface FormSegmentProps {
  type: EPreviewFormType
  item: ITemplate | ITicket
  onSubmit?: Function
  onChange?: Function
  isFillMode?: boolean
  isUIAllowSelectOptions?: boolean
  isShowSignatures: boolean
  isShowCustomerPhotoFields: boolean
}

/**
 *  資料流：
 *  所有的資料都是透過 onChange callback 向上傳遞，不會用到 onSubmit
 */
function FormSegment({
  type,
  item,
  onSubmit,
  onChange,
  isFillMode,
  isUIAllowSelectOptions,
  isShowSignatures,
  isShowCustomerPhotoFields,
}: FormSegmentProps) {
  /* States */
  const { t } = useTranslation()
  const [initialValues, setInitialValues] = useState()
  const [isInitialized, setIsInitialized] = useState(false)

  //@ts-ignore
  Validators.messages = {
    //@ts-ignore
    ...Validators.messages,
    required: t('Required'),
  }

  /* Hooks */
  // 初始化表單的所有欄位
  useMemo(() => {
    if (!item || !item.key || isInitialized) return
    let customFieldsData
    let itnCustomFieldsData

    if (item.custom_fields_data) {
      try {
        customFieldsData = JSON.parse(item.custom_fields_data)
      } catch (err) {
        // pass
      }
    }
    if (item.itn_custom_fields_data) {
      try {
        itnCustomFieldsData = JSON.parse(item.itn_custom_fields_data)
      } catch (err) {
        // pass
      }
    }

    if (type === EPreviewFormType.template) {
      // 理論上 template 相關的表單內容不會使用此元件顯示，改以 TemplateForm 元件取代
      const template = item as ITemplate
      const {
        name,
        serv_ppl_name,
        serv_ppl_phone,
        serv_ppl_2_name,
        serv_ppl_2_phone,
        send_sms_to_user,
      } = template
      setInitialValues({
        name,
        serv_ppl_name,
        serv_ppl_phone,
        serv_ppl_2_name,
        serv_ppl_2_phone,
        send_sms_to_user,
        ...customFieldsData,
        ...itnCustomFieldsData,
      })
    } else {
      const ticket = item as ITicket
      const {
        customer_name,
        customer_phone,
        serv_ppl_name,
        serv_ppl_phone,
        serv_ppl_2_name,
        serv_ppl_2_phone,
        memo,
      } = ticket
      setInitialValues({
        customer_name,
        customer_phone,
        serv_ppl_name,
        serv_ppl_phone,
        serv_ppl_2_name,
        serv_ppl_2_phone,
        memo,
        ...customFieldsData,
        ...itnCustomFieldsData,
      })
    }

    setIsInitialized(true)
  }, [item, isInitialized, type])

  const getPreviewFormTemplate = useCallback(
    (props: FormTemplateCommonProps) => (
      <div style={{ position: 'relative' }}>
        <div
          className={classnames({
            'form-disabled': !isFillMode,
            'allow-select-options': isUIAllowSelectOptions,
          })}
        >
          <PreviewFormTemplate
            {...props}
            // 使用 onChange callback 將表單的資料向上傳遞
            onChange={onChange}
            // 是否顯示其他欄位
            isShowSignature={isShowSignatures}
            isShowCustomerPhotoFields={isShowCustomerPhotoFields}
          />
        </div>
      </div>
    ),
    [
      isFillMode,
      isUIAllowSelectOptions,
      onChange,
      isShowSignatures,
      isShowCustomerPhotoFields,
    ]
  )

  return (
    <FormRenderer
      initialValues={initialValues}
      schema={loadUISchema(type, item)}
      componentMapper={customComponentMapper}
      FormTemplate={getPreviewFormTemplate}
      // onSubmit 沒有用
      onSubmit={
        onSubmit
          ? (values, formApi) => {
              onSubmit({
                values,
              })
            }
          : () => {}
      }
      className="form-container"
    />
  )
}

export default FormSegment
