import { date, id, phone_number } from './base'
import { INotificationBellBase } from './notificationBell'
import { ISuiquiFile } from './suiquiFile'
import { ITemplateBase } from './template'

export enum ETicketStatus {
  InProgress = 'InProgress', // 進行中
  Done = 'Done', // 已完成
  Observing = 'Observing', // 觀察中
  Waiting = 'Waiting', // 待確認
  Preparing = 'Preparing', // 備料中
}

export interface ITicket
  extends ITemplateBase,
    ITicketPermission,
    ITicketFiles {
  id: id
  short_id: id
  customer_name: string | null
  customer_phone: phone_number | null
  memo: string | null
  display_name: string
  short_display_name: string
  serial_number?: string | null
  ticket_status?: ETicketStatus | ETicketStatus[]
  create_datetime: date | null
  modified_datetime: date | null
  expected_end_date: date | null
  actual_end_date: date | null
  observation_start_date: date | null
  notification_bells: ITicketNotificationBell[]
}

export enum ETicketFunction {
  ticket_read = 'ticket_read',
  ticket_update = 'ticket_update',
  ticket_delete = 'ticket_delete',
  ticket_comment = 'ticket_comment',
  ticket_status_change = 'ticket_status_change',
}

export enum ETicketUserType {
  service_ppl = 'service_ppl',
  user = 'user',
}
export interface ITicketPermission {
  function_list: ETicketFunction[]
}

export enum EListTicketSegment {
  list = 'list',
  dashboard = 'dashboard',
}

export enum ETicketFileLabel {
  fix_before = 'fix_before',
  fix_in_progress = 'fix_in_progress',
  fix_after = 'fix_after',
  fix_attachment = 'fix_attachment',
}

export type TTicketFileLabel = Record<
  ETicketFileLabel,
  {
    files: ISuiquiFile[]
    photos: ISuiquiFile[]
  }
>

export interface ITicketFiles extends TTicketFileLabel {}

export interface ITicketFilesUpload {
  key: id
  file?: Blob[]
  files?: Blob[]
  filename?: string
  filenames?: string[]
  hash?: number
  hashFunc?: Function
  name?: string
  label?: ETicketFileLabel
}

// 節點提醒
export interface ITicketNotificationBell extends INotificationBellBase {
  name: string // 提醒的名稱
  notify_date: date // 提醒日期
  is_active: boolean // 是否啟用提醒
  day_offset?: never
}
