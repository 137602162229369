import { combineReducers } from 'redux'
import { StateType } from 'typesafe-actions'
import { templateReducer as newTemplateReducer } from '../templateSlice'
import { authReducer } from '../epics/auth'
import { commentReducer } from '../epics/comment'
import { downloadFileReducer } from '../epics/downloadFile'
import { downloadZipReducer } from '../epics/downloadZip'
import { searchReducer } from '../epics/search'
import { teamReducer } from '../epics/team'
import { templateReducer } from '../epics/template'
import { ticketReducer } from '../epics/ticket'
import { userReducer } from '../epics/user'
import authDuck from './auth'

const rootReducer = combineReducers({
  authDuck,
  auth: authReducer,
  user: userReducer,
  team: teamReducer,
  template: templateReducer,
  ticket: ticketReducer,
  search: searchReducer,
  comment: commentReducer,
  download: downloadFileReducer,
  downloadZip: downloadZipReducer,
  newTemplate: newTemplateReducer,
})

export type AuthState = StateType<typeof authReducer>
export type UserState = StateType<typeof userReducer>
export type TemplateState = StateType<typeof templateReducer>
export type TeamState = StateType<typeof teamReducer>
export type TicketState = StateType<typeof ticketReducer>
export type SearchState = StateType<typeof searchReducer>
export type CommentState = StateType<typeof commentReducer>
export type DownloadState = StateType<typeof downloadFileReducer>
export type DownloadZipState = StateType<typeof downloadZipReducer>
export type RootState = StateType<typeof rootReducer>
export type ThunkConfig = { state: RootState }

export default rootReducer
