import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import {
  caretUp,
  cube,
  language,
  personCircleOutline,
  refresh,
} from 'ionicons/icons'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { useTitle } from 'react-use'
import useLocalForge from '../hooks/use-local-forge'
import TeamSwitchModal from '../modals/TeamSwitchModal'
import { DELAY, DELAY_SHORT } from '../models/constants'
import { fetchUser } from '../store/epics/user'
import './Settings.scss'

const Settings: React.FC<RouteComponentProps> = () => {
  const { t, i18n } = useTranslation()
  useTitle(t('Suiqui Support'))
  const dispatch = useDispatch()
  const contentRef = useRef(null)
  const [, setCurrentUser] = useLocalForge('suiquiUserData')
  const [, setLang] = useLocalForge('userLang', 'zh')

  const authDuck = useSelector((state: any) => state.authDuck)
  const user = useSelector((state: any) => state.user)

  const [isPageLoading, setIsPageLoading] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showTeamSwitchModal, setShowTeamSwitchModal] = useState(false)
  const [currentLang, setCurrentLang] = useState<string>('')
  const [showClearCacheAlert, setShowClearCacheAlert] = useState(false)

  useIonViewDidEnter(() => {})

  useIonViewDidLeave(() => {})

  useIonViewWillEnter(() => {
    setTimeout(() => {
      if (!authDuck.currentUser) {
        dispatch(fetchUser.request({}))
      }
    }, DELAY)
  }, [dispatch, authDuck.currentUser])

  useIonViewWillLeave(() => {})

  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current!.scrollToTop(DELAY_SHORT)
  }

  const reloadData = () => {
    setShowLoading(true)
    // force reload
    window.location.reload(true)
  }

  useEffect(() => {
    if (user.currentUser) {
      setShowLoading(false)
    }
  }, [user.currentUser])

  const handleClickSwitchTeam = (event: any) => {
    event.preventDefault()
    setShowTeamSwitchModal(true)
  }

  const handleClickChangeLanguage = async (event: any) => {
    let lang = event.detail.value
    lang = lang === 'zh' ? 'zh-TW' : lang
    if (lang === i18n.language) return
    setLang(lang)
    await i18n.changeLanguage(lang)
    moment.locale(lang)
  }

  useMemo(() => {
    const lang = i18n.language === 'zh' ? 'zh-TW' : i18n.language
    setCurrentLang(lang)
  }, [i18n.language, setCurrentLang])

  return isPageLoading ? (
    <div className="ion-text-center centered">
      <IonSpinner color="primary" name="crescent"></IonSpinner>
    </div>
  ) : (
    <IonPage className="page-settings">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t('Settings')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef} scrollEvents={true}>
        <IonHeader>
          <IonToolbar color="light">
            <IonButtons slot="start">
              <IonButton color="primary" fill="clear" onClick={reloadData}>
                <IonIcon slot="icon-only" icon={refresh} />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              {authDuck?.isUserLoaded && (
                <IonButton
                  className="team-name"
                  fill="clear"
                  size="default"
                  strong={true}
                  href="#"
                  routerDirection="root"
                  routerLink="/share"
                >
                  <IonIcon slot="start" icon={personCircleOutline} />
                  <IonLabel>
                    <IonText color="primary">
                      {authDuck.currentUser?.displayname}
                    </IonText>
                  </IonLabel>
                </IonButton>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol size="12" size-md="6" className="ion-align-self-start">
              <IonItemDivider>
                <IonLabel color="primary">{t('Personal')}</IonLabel>
              </IonItemDivider>
              <IonItem onClick={handleClickSwitchTeam} href="#">
                <IonIcon slot="start" icon={cube} />
                <IonLabel>{t('Switch Team')}</IonLabel>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="6" className="ion-align-self-start">
              <IonItemDivider>
                <IonLabel color="primary">{t('Language')}</IonLabel>
              </IonItemDivider>

              <IonItem>
                <IonIcon slot="start" icon={language} />
                <IonLabel>{t('Language')}</IonLabel>
                <IonSelect
                  disabled={!currentLang}
                  value={currentLang}
                  okText={t('OK')}
                  cancelText={t('Cancel')}
                  onIonChange={handleClickChangeLanguage}
                >
                  <IonSelectOption value="zh-TW">中文</IonSelectOption>
                  <IonSelectOption value="en">English</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem lines="none" className="ion-text-center">
                <IonLabel>v{`${process.env.REACT_APP_VERSION}`}</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="ion-text-center">
          <IonButton
            color="danger"
            fill="clear"
            size="large"
            onClick={scrollToTop}
          >
            <IonIcon slot="icon-only" icon={caretUp} />
          </IonButton>
        </div>

        <IonModal isOpen={showTeamSwitchModal} backdropDismiss={false}>
          <TeamSwitchModal
            isOpen={showTeamSwitchModal}
            dismiss={() => setShowTeamSwitchModal(false)}
          />
        </IonModal>

        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          spinner="crescent"
        />
      </IonContent>
      <IonFooter color="light">
        {authDuck?.isUserLoaded && (
          <div className="ion-text-center">
            <IonButton
              color="primary"
              fill="clear"
              size="small"
              className="immutable"
            >
              <IonLabel>{authDuck.currentUser?.team?.name}</IonLabel>
            </IonButton>
          </div>
        )}
      </IonFooter>

      <IonAlert
        keyboardClose={true}
        isOpen={showClearCacheAlert}
        onDidDismiss={() => setShowClearCacheAlert(false)}
        header={t('Clear Download Cache')}
        message={t('Download Caches are cleared.')}
        buttons={[
          {
            text: t('OK'),
            cssClass: 'ok',
            handler: (params) => {
              window.location.reload(true)
            },
          },
        ]}
      />
    </IonPage>
  )
}

export default Settings
