import { ajax } from 'rxjs/ajax'
import { API, PUBLIC_TOKEN } from '../../helpers/base'
import { public_token } from '../../models/base'
import { ITicket } from '../../models/ticket'

interface FetchPublicTemplateRequest {
  token: public_token
}

export const fetchPublicTemplate = ({ token }: FetchPublicTemplateRequest) =>
  ajax({
    url: API('api/template', 'SUPPORT'),
    method: 'POST',
    headers: PUBLIC_TOKEN(token),
    responseType: 'json',
  })

interface CreatePublicTicketRequest {
  token: public_token,
  params: Partial<ITicket>
}

export const createPublicTicket = ({ token, params }: CreatePublicTicketRequest) =>
  ajax({
    url: API('api/ticket/create', 'SUPPORT'),
    method: 'POST',
    body: params,
    headers: PUBLIC_TOKEN(token),
    responseType: 'json',
  })
