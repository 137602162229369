import { ajax } from 'rxjs/ajax'
import { API, JWT } from '../helpers/base'
import { ITeam } from '../models/team'

export const get = ({ token }: { token: string }) =>
  ajax({
    url: API('team', 'AUTH'),
    method: 'GET',
    headers: JWT(token),
    responseType: 'json',
  })

export const update = ({
  token,
  params,
}: {
  token: string
  params: Partial<ITeam>
}) =>
  ajax({
    url: API('team/update', 'AUTH'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })
