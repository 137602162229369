/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import { TextField } from '@mui/material'
import { checkmark, close } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { Signature } from '../../models/template'
import SignatureCanvas from 'react-signature-canvas'
import { useEffect, useRef, useState } from 'react'
import useComponentSize from '@rehooks/component-size'
import './SignatureModal.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearCurrentSignature,
  clearQuickSign,
  clearToastMessage,
  getQuickSignSignature,
  getSignature,
  signSignature,
  unsignSignature,
} from '../../store/epics/ticket'
import { id } from '../../models/base'
import LoadingSpinner from '../LoadingSpinner'
import { useRemoveIonPageInvisible } from '../../hooks/useRemoveIonPageInvisible'
import useToast from '../../hooks/useToast'

interface SignatureModalProps {
  signatureKey: id
  dismiss: () => void
}

const SignatureModal = ({ signatureKey, dismiss }: SignatureModalProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pageRef = useRemoveIonPageInvisible()
  const signatureCanvasRef = useRef<SignatureCanvas>(null)
  const { width: modalWidth } = useComponentSize(pageRef)
  const [canvasWidth, setCanvasWidth] = useState(300) // max(modalWidth * 0.75, 300)
  const widthHeightRatio = 0.48 // canvasHeight = canvasWidth * 0.48
  const [signatureData, setSignatureData] = useState<string | undefined>()

  // Toast
  const { showToast, toastProps } = useToast()
  const toastMessage = useSelector((state: any) => state.ticket.toastMessage)

  // 正在檢視的簽名欄位
  const signature: Signature | undefined = useSelector(
    (state: any) => state.ticket.currentSignature
  )

  // 是否在讀取正在檢視的簽核欄位
  const isLoadingCurrentSignature = useSelector(
    (state: any) => state.ticket.isLoadingCurrentSignature
  )

  // 用來快速簽名的簽名
  const quickSignSignatureValue: string = useSelector(
    (state: any) => state.ticket.quickSignSignatureValue
  )

  // 簽名板是否為空
  const [isEmpty, setIsEmpty] = useState(
    signature?.value === null || signature?.value === undefined
  )

  // 是否修改過簽名板
  const [isModified, setIsModified] = useState(false)

  const saveSignature = () => {
    if (!signature) return
    setIsModified(false)
    const sign = signatureCanvasRef.current?.toDataURL()
    if (sign && !isEmpty) {
      // 簽名
      dispatch(
        signSignature.request({
          key: signature.key,
          value: sign,
        })
      )
    } else {
      // 刪除簽名
      dispatch(
        unsignSignature.request({
          key: signature.key,
        })
      )
    }
  }

  // 清除目前的簽名
  const clearSignature = () => {
    if (!signature) return
    signatureCanvasRef.current?.clear()
    setIsEmpty(true)
    if (!signature.value) {
      setIsModified(false)
    } else {
      setIsModified(true)
    }
  }

  // 快速簽名
  const quickSign = () => {
    // 向後端請求使用者設定的預設簽名
    dispatch(getQuickSignSignature.request())
  }

  // 關閉 modal
  const dismissModal = () => {
    // 在每次關閉 modal 時清除 state 中的 current signature，下次打開 modal 時才會重新讀取
    dispatch(clearCurrentSignature())
    dismiss()
  }

  // 每次打開 modal 時，讀取完整的簽核欄位內容
  useEffect(() => {
    dispatch(getSignature.request({ key: signatureKey }))
  }, [dispatch, signatureKey])

  // 動態調整簽名板的大小
  useEffect(() => {
    setCanvasWidth(Math.max(modalWidth * 0.75, 300))
    setSignatureData(signatureCanvasRef.current?.toDataURL()) // 儲存原本畫布上的簽名
  }, [modalWidth])

  // 簽名板的大小調整後，畫布會被重置，須重新讀取回原本的簽名
  useEffect(() => {
    if (!signatureData) return
    signatureCanvasRef.current?.fromDataURL(signatureData, {
      width: canvasWidth,
      height: canvasWidth * widthHeightRatio,
    })
  }, [canvasWidth, signatureData])

  // 當收到快速簽名的內容時，將簽名顯示在簽名板上
  useEffect(() => {
    if (!quickSignSignatureValue) return
    setIsEmpty(false)
    setIsModified(true)
    signatureCanvasRef.current?.clear()
    signatureCanvasRef.current?.fromDataURL(quickSignSignatureValue, {
      width: canvasWidth,
      height: canvasWidth * widthHeightRatio,
    })
    dispatch(clearQuickSign()) // 清除 state 中的快速簽名，不然第二次快速簽名不會觸發更新
  }, [quickSignSignatureValue, dispatch])

  // 簽核欄位的內容更新時，將其更新在簽名板上
  useEffect(() => {
    if (!signature?.value) return
    setIsEmpty(false)
    setIsModified(false)
    signatureCanvasRef.current?.clear()
    signatureCanvasRef.current?.fromDataURL(signature.value, {
      width: canvasWidth,
      height: canvasWidth * widthHeightRatio,
    })
  }, [signature?.value, dispatch])

  // 顯示更新權限成功 toast
  useEffect(() => {
    if (!toastMessage) return
    showToast(t(toastMessage))
    dispatch(clearToastMessage()) // 移除 state 中的 toastMessage
  }, [t, showToast, toastMessage, dispatch])

  return (
    <IonPage className="signature-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          {/* 標題 */}
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Support')}</IonButton>
            <span className="subtitle">{t('Signature Fields')}</span>
          </IonTitle>

          <IonButtons slot="end">
            {/* 關閉按鈕 */}
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={dismissModal}
            >
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {isLoadingCurrentSignature ? (
        <LoadingSpinner />
      ) : (
        <IonContent className="ion-padding bg" scrollEvents={true}>
          <IonList lines="inset">
            <h5>{t('Signature Name')}</h5>
            <div className="field-name-input">
              {/* 簽名職稱 */}
              <TextField
                fullWidth
                required
                className="input-field"
                value={signature?.name}
                inputProps={{
                  readOnly: true,
                }}
              />
            </div>
          </IonList>

          <div className="signature">
            <div
              style={{
                width: `${canvasWidth}px`,
              }}
            >
              {/* 功能按鈕 */}
              <div className="toolbar">
                <IonButtons>
                  <IonButton
                    onClick={clearSignature}
                    fill="outline"
                    color="primary"
                  >
                    {t('Clear')}
                  </IonButton>
                  <IonButton onClick={quickSign} fill="solid" color="primary">
                    {t('Quick sign')}
                  </IonButton>
                </IonButtons>
              </div>

              {/* 簽名板 */}
              <div
                style={{
                  width: `${canvasWidth}px`,
                  height: `${canvasWidth * widthHeightRatio}px`,
                }}
                className="signature-canvas"
              >
                <SignatureCanvas
                  ref={signatureCanvasRef}
                  penColor="black"
                  canvasProps={{
                    width: canvasWidth,
                    height: canvasWidth * widthHeightRatio,
                  }}
                  onBegin={() => {
                    setIsEmpty(false)
                    setIsModified(true)
                  }}
                />
              </div>

              <IonRow class="ion-justify-content-center ion-margin-vertical">
                {/* 完成按鈕 */}
                <IonButton
                  onClick={saveSignature}
                  disabled={!isModified}
                  className="save-btn ion-margin-bottom"
                >
                  <IonIcon icon={checkmark} slot="start" />
                  <IonLabel slot="end">{t('Confirm')}</IonLabel>
                </IonButton>
              </IonRow>
            </div>
          </div>
        </IonContent>
      )}

      {/* Toast */}
      <IonToast {...toastProps} />
    </IonPage>
  )
}

export default SignatureModal
