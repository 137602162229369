import { combineEpics } from 'redux-observable'
import { of, pipe } from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import { catchError, concatMap, tap } from 'rxjs/operators'
import { PayloadAction } from 'typesafe-actions'
import publicTicketEpic from '../public_ticket/epics'
import authEpic, {
  forceAuthErrorAlert,
  forceAuthLogout,
  prepareAuthTeamSwitching,
} from './auth'
import commentEpic from './comment'
import downloadFileEpic from './downloadFile'
import downloadZipEpic from './downloadZip'
import searchEpic from './search'
import teamEpic from './team'
import templateEpic from './template'
import ticketEpic from './ticket'
import { ReactRouterAction } from './types'
import userEpic from './user'

const epics = (action$: any, state$: any, deps: any) => {
  return combineEpics<any>(
    authEpic,
    userEpic,
    teamEpic,
    templateEpic,
    ticketEpic,
    publicTicketEpic,
    searchEpic,
    commentEpic,
    downloadFileEpic,
    downloadZipEpic
  )(action$, state$, deps).pipe(
    tap(
      (action: PayloadAction<string, AjaxResponse | ReactRouterAction>) => {}
    ),
    catchError((error, source$) => {
      return source$
    }),
    pipe(
      concatMap((action: PayloadAction<string, AjaxResponse>) => {
        if (action.payload.status === 401) {
          return of(action, forceAuthLogout(action))
        } else if (action.payload.status === 400) {
          return of(action)
        } else if (action.payload.status === 403) {
          return of(action)
        } else if (action.payload.status === 203) {
          // 若後端 API 回傳的 status code 為 203，dispatch 切換團隊的 redux action
          return of(
            {
              ...action,
              payload: action.payload,
            },
            prepareAuthTeamSwitching(action.payload)
          )
        } else if (
          action.payload.status === 500 ||
          (action.payload.status !== 404 &&
            action.payload.status >= 402 &&
            action.payload.status < 500)
        ) {
          return of(action, forceAuthErrorAlert(action.payload))
        } else {
          return of(action)
        }
      })
    )
  )
}

export default epics
