import { RefresherEventDetail } from '@ionic/core'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import { downloadOutline } from 'ionicons/icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import Dashboard from '../../components/Dashboard'
import LoadingSpinner from '../../components/LoadingSpinner'
import ScrollToTopButton from '../../components/ScrollToTopButton'
import { TemplateListCard } from '../../components/TemplateListCard'
import useModal from '../../hooks/useModal'
import { EListTicketSegment } from '../../models/ticket'
import { RootState } from '../../store/ducks'
import {
  fetchTemplateList,
  fetchTemplateDashboardAll,
} from '../../store/templateSlice'
import ExportProjectsModal from './ExportTemplatesModal'
import './HomePage.scss'

type HomeProps = {} & RouteComponentProps

const Home = (props: HomeProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isShowExportModal, showExportModal, dismissExportModal] = useModal()
  const authDuck = useSelector((state: any) => state.authDuck)

  // IonContent 的 reference
  const contentRef = useRef<HTMLIonContentElement | null>(null)

  const templates = useSelector(
    (state: RootState) => state.newTemplate.templates
  )
  const state = useSelector((state: RootState) => state.newTemplate)
  const { isLoading } = state

  // 手機版控制 SegmentType 的狀態
  const [listType, setListType] = useState<EListTicketSegment>(
    EListTicketSegment.list
  )

  // 從後端取得專案列表的資訊
  const fetchTemplates = useCallback(() => {
    // include_stats: 後端回傳的 template 包含統計數據
    dispatch(fetchTemplateList({ include_stats: true }))
  }, [dispatch])

  // 從後端取得專案總覽的統計資料
  const fetchDashboard = useCallback(() => {
    dispatch(fetchTemplateDashboardAll({}))
  }, [dispatch])

  // 當列表下拉時，觸發頁面重新整理
  const doRefresh = useCallback(
    (event: CustomEvent<RefresherEventDetail>) => {
      event.detail.complete() // 隱藏 IonRefresher 的 loading 圖示
      fetchTemplates() // 重新取得專案列表
      fetchDashboard() // 重新取得專案總覽統計資訊
    },
    [fetchTemplates, fetchDashboard]
  )

  // 手機版切換 Segment 的 handler
  const handleSegmentChanged = (name?: EListTicketSegment) => {
    if (!name) return
    setListType(EListTicketSegment[name])
  }

  // 初始化時自動向後端取得專案列表與統計資訊
  // 備註：只會在剛取得 api token 時執行一次
  useEffect(() => {
    if (!authDuck.isUserLoaded) return
    fetchTemplates()
    fetchDashboard()
  }, [authDuck.isUserLoaded, fetchTemplates, fetchDashboard])

  return (
    <IonPage className="page-home">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t('Suiqui Support')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding bg" ref={contentRef} scrollEvents>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {/* 頁面讀取提示 */}
        {isLoading && <LoadingSpinner />}

        {!isLoading && (
          <>
            <IonToolbar color="light">
              {/* 團隊名稱（只在手機畫面顯示） */}
              {isPlatform('mobile') && (
                <IonListHeader className="ion-margin-bottom">
                  <IonLabel>
                    <h2>{authDuck?.currentUser?.team?.name}</h2>
                  </IonLabel>
                </IonListHeader>
              )}
              {/* 「所有專案」（只在電腦版畫面顯示） */}
              {isPlatform('desktop') && (
                <IonText slot="start" className="ion-padding-start">
                  <h2>{t('Current Templates')}</h2>
                </IonText>
              )}

              {/* 「匯出所有專案」按鈕 */}
              <IonButton
                color="primary"
                fill="clear"
                slot="end"
                onClick={showExportModal}
              >
                <IonIcon slot="start" icon={downloadOutline} />
                <IonLabel>{t('Export projects')}</IonLabel>
              </IonButton>
            </IonToolbar>

            {/* 專案統計元件（所有專案統計資料的加總，電腦版） */}
            <div className="ion-hide-md-down">
              <Dashboard stats={state.dashboard}></Dashboard>
            </div>

            {/* 子頁面選單（「專案列表」/「專案統計」）（手機版）*/}
            <IonSegment
              value={listType}
              className="ion-hide-md-up"
              onIonChange={(e) => {
                // type assertion to avoid linter error
                handleSegmentChanged(e.detail.value as EListTicketSegment)
              }}
            >
              <IonSegmentButton value="list">
                <IonLabel>{t('Projects')}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="dashboard">
                <IonLabel>{t('Dashboard')}</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            {/* 專案統計元件（手機版） */}
            {listType === EListTicketSegment.dashboard && (
              <div className="ion-hide-md-up">
                <Dashboard stats={state.dashboard}></Dashboard>
              </div>
            )}

            {listType === EListTicketSegment.list && (
              <IonList lines="inset">
                {/* 沒有專案提示 */}
                {templates.length === 0 && !state.isLoading && (
                  <IonCard>
                    <IonCardHeader color="light">
                      <IonCardTitle className="ion-text-center">
                        <IonLabel color="medium">{t('No Projects')}</IonLabel>
                      </IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                )}

                {/* 專案列表 */}
                {templates.map((template) => (
                  <TemplateListCard template={template}></TemplateListCard>
                ))}
              </IonList>
            )}

            {/* 回到最上面按鈕 */}
            <ScrollToTopButton contentRef={contentRef} />
          </>
        )}
      </IonContent>

      {/* 匯出專案 modal */}
      <IonModal isOpen={isShowExportModal} backdropDismiss={false}>
        {isShowExportModal && (
          <ExportProjectsModal dismiss={dismissExportModal} />
        )}
      </IonModal>
    </IonPage>
  )
}

export default Home
