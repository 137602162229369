import { combineEpics, Epic } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import {
  createPublicTicket,
  fetchPublicTemplate,
  PublicTicketAction,
} from './actions'

export const fetchPublicTemplateEpic: Epic<
  PublicTicketAction,
  PublicTicketAction,
  RootState,
  typeof Providers
> = (action$, state$, { publicTicketAPI }) => {
  return action$.pipe(
    filter(isActionOf(fetchPublicTemplate.request)),
    switchMap((action) =>
      publicTicketAPI
        .fetchPublicTemplate({
          token: action.payload,
        })
        .pipe(
          map((data) => fetchPublicTemplate.success(data.response)),
          catchError((message: Error) =>
            of(fetchPublicTemplate.failure(message))
          ),
          takeUntil(
            action$.pipe(filter(isActionOf(fetchPublicTemplate.cancel)))
          )
        )
    )
  )
}

export const createPublicTicketEpic: Epic<
  PublicTicketAction,
  PublicTicketAction,
  RootState,
  typeof Providers
> = (action$, state$, { publicTicketAPI }) => {
  return action$.pipe(
    filter(isActionOf(createPublicTicket.request)),
    switchMap((action) =>
      publicTicketAPI
        .createPublicTicket({
          token: action.payload.publicToken,
          params: action.payload,
        })
        .pipe(
          map((data) => createPublicTicket.success(data.response)),
          catchError((message: Error) =>
            of(createPublicTicket.failure(message))
          ),
          takeUntil(action$.pipe(filter(isActionOf(createPublicTicket.cancel))))
        )
    )
  )
}

const publicTicketEpic = combineEpics<any>(
  fetchPublicTemplateEpic,
  createPublicTicketEpic
)

export default publicTicketEpic
