import { RefresherEventDetail } from '@ionic/core'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonLoading,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import {
  add,
  caretUp,
  filterOutline,
  refresh,
  downloadOutline,
  printOutline,
} from 'ionicons/icons'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { useDebounce } from 'react-use'
import Dashboard from '../../components/Dashboard'
import { inFunctionList } from '../../helpers/base'
import TicketFilterModal from './TicketFilterModal'
import { DELAY, DELAY_LONG, DELAY_SHORT } from '../../models/constants'
import { ETemplateFunction } from '../../models/template'
import { EListTicketSegment, ITicket } from '../../models/ticket'
import { RootState, SearchState, TicketState } from '../../store/ducks'
import { fetchTicketSearch, getTicketSearchCsv } from '../../store/epics/search'
import { fetchTemplateDashboard } from '../../store/epics/template'
import { fetchTicketList } from '../../store/epics/ticket'
import { ReactRouterAction } from '../../store/epics/types'
import './TicketList.scss'
import TicketListCard from './TicketListCard'
import {
  clearStatus,
  fetchTemplate,
  NewTemplateState,
  TemplateState,
} from '../../store/templateSlice'
import TeamSwitchPage from '../../components/TeamSwitchPage'
import useModal from '../../hooks/useModal'
import PrintTicketsModal from './PrintTicketsModal'

export type ListTicketProps = {} & RouteComponentProps & ReactRouterAction

const TicketList = ({ location, match, ...props }: ListTicketProps) => {
  const { t } = useTranslation()
  const authDuck = useSelector((state: any) => state.authDuck)
  const dispatch = useDispatch()
  const contentRef = useRef(null)
  const searchbarRef = useRef(null)
  const pageRef = useRef(null)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [showEmpty, setShowEmpty] = useState(false)
  const [isSearchMode, setIsSearchMode] = useState(false)
  const [isAllowSearch, setIsAllowSearch] = useState(false)
  const [showTicketFilterModal, setShowTicketFilterModal] = useState(false)
  const [ticketFilterInitialValues, setTicketFilterInitialValues] = useState()
  const [listType, setListType] = useState<EListTicketSegment>(
    EListTicketSegment.list
  )
  const [shouldRefresh, setShouldRefresh] = useState(false)
  const [
    showPrintTicketsModal,
    openPrintTicketsModal,
    dismissPrintTicketsModal,
  ] = useModal()
  const [showIonLoading, dismissIonLoading] = useIonLoading() // 等待的視覺效果

  const ticket = useSelector((state: TicketState) => state.ticket)
  const status = useSelector((state: RootState) => state.newTemplate.status)
  const { dashboard }: TemplateState = useSelector(
    (state: any) => state.template
  )
  const search = useSelector((state: SearchState) => state.search)

  const [keyword, setKeyword] = useState('')
  const [debouncedKeyword, setDebouncedKeyword] = useState('')
  useDebounce(
    () => {
      //@ts-ignore
      setDebouncedKeyword(keyword)
    },
    1000,
    [keyword]
  )

  const auth = useSelector((state: any) => state.auth)

  const handleCompositionEnd = (event: any) => {
    setKeyword(event.target.value || '')
  }

  useIonViewDidEnter(() => {
    if (isPlatform('desktop')) {
      //@ts-ignore
      searchbarRef.current?.addEventListener(
        'compositionend',
        handleCompositionEnd
      )
    }
    // 不知道為什麼若是重新導向至這個頁面，畫面會是空白，需使用與 modal 相同的 workaround
    // @ts-ignore
    pageRef.current?.classList.remove('ion-page-invisible')
    dispatch(fetchTemplate({ key: match.params.key }))
  }, [isPlatform, searchbarRef, pageRef])

  const doRefresh = useCallback(
    (event?: CustomEvent<RefresherEventDetail>) => {
      setIsProcessing(true)

      if (isSearchMode) {
        dispatch(
          fetchTicketSearch.request({
            key: match.params.key,
            keyword: debouncedKeyword,
            start_date: search.start_date,
            end_date: search.end_date,
            kw_custom_fields: search.kw_custom_fields,
            kw_itn_custom_fields: search.kw_itn_custom_fields,
            event,
            reload: true, // 以相同條件重新搜尋
          })
        )
      } else {
        dispatch(fetchTicketList.cancel())

        dispatch(
          fetchTicketList.request({
            key: match.params.key,
            event,
          })
        )

        dispatch(
          fetchTemplateDashboard.request({
            key: match.params.key,
          })
        )
      }
    },
    [
      dispatch,
      match.params.key,
      setIsProcessing,
      isSearchMode,
      search.start_date,
      search.end_date,
      search.kw_custom_fields,
      search.kw_itn_custom_fields,
      debouncedKeyword,
    ]
  )

  const resetFilter = () => {
    setTicketFilterInitialValues(undefined)
  }

  const resetSearch = () => {
    setKeyword('')
    setDebouncedKeyword('')
    setIsAllowSearch(false)
    setIsSearchMode(false)
    dispatch(fetchTicketSearch.cancel())
    setTimeout(() => {
      setIsAllowSearch(true)
    }, DELAY)
  }

  useIonViewWillEnter(() => {
    if (!ticket.shouldReload) return
    doRefresh()
  }, [doRefresh, ticket.shouldReload])

  useIonViewWillLeave(() => {
    setIsProcessing(false)
    setListType(EListTicketSegment.list)
  }, [setDebouncedKeyword, setIsProcessing, setListType])

  useMemo(() => {
    if (!authDuck.isUserLoaded) return

    doRefresh()
  }, [doRefresh, authDuck.isUserLoaded])

  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current!.scrollToTop(DELAY_SHORT)
  }

  const loadData = (event?: any) => {
    if (isSearchMode) {
      dispatch(
        fetchTicketSearch.request({
          key: match.params.key,
          cursor: search.cursor,
          keyword: debouncedKeyword,
          start_date: search.start_date,
          end_date: search.end_date,
          kw_custom_fields: search.kw_custom_fields,
          kw_itn_custom_fields: search.kw_itn_custom_fields,
        })
      )
    } else {
      dispatch(
        fetchTicketList.request({
          key: match.params.key,
          cursor: ticket.cursor,
          event,
        })
      )
    }
  }

  useMemo(() => {
    if (isSearchMode) return
    if (!ticket?.tickets) return
    if (ticket.isDone && ticket?.tickets?.length >= 0) {
      setShowEmpty(true)
      setIsPageLoading(false)
      setIsAllowSearch(true)
      setTimeout(() => {
        setIsProcessing(false)
      }, DELAY_LONG)
    }
  }, [
    isSearchMode,
    ticket.isDone,
    ticket.tickets,
    setIsPageLoading,
    setIsProcessing,
    setShowEmpty,
    setIsAllowSearch,
  ])

  useMemo(() => {
    if (!isSearchMode) return
    if (!search?.tickets) return
    if (search.isDone && search?.tickets?.length >= 0) {
      setShowEmpty(true)
      setIsPageLoading(false)
      setIsAllowSearch(true)
      setTimeout(() => {
        setIsProcessing(false)
      }, DELAY_LONG)
    }
  }, [
    isSearchMode,
    search.isDone,
    search.tickets,
    setIsPageLoading,
    setIsProcessing,
    setShowEmpty,
  ])

  useMemo(() => {
    if (!isAllowSearch) return
    if (!debouncedKeyword || debouncedKeyword === search.keyword) return

    setTimeout(() => {
      setIsProcessing(true)
      setIsSearchMode(true)
    }, DELAY_SHORT)

    dispatch(
      fetchTicketSearch.request({
        key: match.params.key,
        reload: true,
        keyword: debouncedKeyword,
        start_date: search.start_date,
        end_date: search.end_date,
        kw_custom_fields: search.kw_custom_fields,
        kw_itn_custom_fields: search.kw_itn_custom_fields,
      })
    )
  }, [
    dispatch,
    debouncedKeyword,
    search.keyword,
    setIsProcessing,
    setIsSearchMode,
    match.params.key,
    isAllowSearch,
    search.kw_custom_fields,
    search.kw_itn_custom_fields,
    search.start_date,
    search.end_date,
  ])

  const dismissTicketFilterModal = (values: any) => {
    const {
      formData,
      start_date,
      end_date,
      kw_custom_fields,
      kw_itn_custom_fields,
    } = values || {}
    setShowTicketFilterModal(false)
    setTicketFilterInitialValues(formData?.values)

    if (kw_custom_fields || kw_itn_custom_fields || start_date || end_date) {
      setIsSearchMode(true)
      setIsProcessing(true)
      dispatch(
        fetchTicketSearch.request({
          key: match.params.key,
          reload: true,
          keyword: debouncedKeyword,
          start_date,
          end_date,
          kw_custom_fields: values.kw_custom_fields,
          kw_itn_custom_fields: values.kw_itn_custom_fields,
        })
      )
    } else {
      resetFilter()
      resetSearch()
    }
  }

  const handleSegmentChanged = (name?: string) => {
    if (!name) return
    setListType(EListTicketSegment[name as keyof typeof EListTicketSegment])
  }

  useMemo(() => {
    if (!shouldRefresh) return
    doRefresh()
    setShouldRefresh(false)
  }, [doRefresh, setShouldRefresh, shouldRefresh])

  // 將搜尋結果匯出成 csv 並下載
  const onExport = useCallback(() => {
    dispatch(
      getTicketSearchCsv.request({
        name: ticket?.name,
        key: match.params.key,
        keyword: debouncedKeyword,
        start_date: search.start_date,
        end_date: search.end_date,
        kw_custom_fields: search.kw_custom_fields,
        kw_itn_custom_fields: search.kw_itn_custom_fields,
      })
    )
  }, [dispatch, match.params.key, debouncedKeyword, search, ticket])

  // 當 status 為 'printed' 時觸發
  useEffect(() => {
    if (status !== 'printed') return
    dismissIonLoading() // 移除等待的視覺效果
    dispatch(clearStatus()) // 清除 status
  }, [status, dismissIonLoading, dispatch])

  // 若目前使用者無權限查閱，顯示切換團隊頁面
  if (auth?.nextTeam) {
    return <TeamSwitchPage />
  }

  return (
    <IonPage className="page-list-ticket" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          {/* 專案名稱 */}
          <IonTitle>{ticket.name}</IonTitle>

          {/* 重新整理按鈕 */}
          <IonButtons slot="end" className="ion-padding-end">
            <IonButton
              disabled={isProcessing}
              fill="clear"
              color="light"
              slot="icon-only"
              onClick={() => doRefresh()}
            >
              <IonIcon icon={refresh}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/* 主頁面 */}
      <IonContent className="bg" ref={contentRef} scrollEvents={true}>
        {/* 向上拉重新整理元件 */}
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        ) : (
          <>
            <div className="ion-padding">
              <IonToolbar color="light">
                {/* 「現有工單」 */}
                {isPlatform('desktop') && (
                  <IonText slot="start" className="ion-padding-start">
                    <h2>{t('Current Tickets')}</h2>
                  </IonText>
                )}

                <IonButtons slot="end">
                  {/* 「列印工單」按鈕 */}
                  <IonButton
                    color="primary"
                    fill="clear"
                    onClick={openPrintTicketsModal}
                  >
                    <IonIcon slot="start" icon={printOutline} />
                    <IonLabel>{t('Print Tickets')}</IonLabel>
                  </IonButton>

                  {/* 「新增工單」按鈕 */}
                  <IonButton
                    disabled={
                      ticket.error ||
                      !inFunctionList(
                        ETemplateFunction.ticket_create,
                        ticket.function_list
                      )
                    }
                    color="primary"
                    fill="clear"
                    size="default"
                    slot={isPlatform('desktop') ? 'end' : ''}
                    expand={isPlatform('desktop') ? undefined : 'full'}
                    routerLink={`/ticket/new`}
                    routerDirection="forward"
                    data-test='create-ticket-btn'
                  >
                    <IonIcon slot="start" icon={add} />
                    <IonLabel>{t('New Ticket')}</IonLabel>
                  </IonButton>
                </IonButtons>
              </IonToolbar>

              {/* 工單統計元件（電腦版） */}
              <div className="ion-hide-md-down">
                <Dashboard stats={dashboard}></Dashboard>
              </div>
            </div>

            {/* 子頁面選單（「工單列表」/「工單統計」）（手機版）*/}
            <IonSegment
              value={listType}
              className="ion-hide-md-up"
              onIonChange={(e) => {
                handleSegmentChanged(e.detail.value)
              }}
            >
              <IonSegmentButton value="list">
                <IonLabel>{t('Tracking List')}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="dashboard">
                <IonLabel>{t('Tracking Dashboard')}</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            {/* 工單統計元件（手機版） */}
            {listType === EListTicketSegment.dashboard && (
              <div className="ion-hide-md-up">
                <Dashboard stats={dashboard}></Dashboard>
                <div className="ion-text-center" hidden={isProcessing}>
                  <IonButton
                    color="warning"
                    fill="clear"
                    size="large"
                    slot="icon-only"
                    onClick={scrollToTop}
                  >
                    <IonIcon icon={caretUp} />
                  </IonButton>
                </div>
              </div>
            )}

            {/* 工單列表 */}
            {listType === EListTicketSegment.list && (
              <>
                <IonItemDivider color="light" sticky>
                  {/* 「篩選」按鈕 */}
                  <IonButton
                    disabled={isProcessing}
                    color={ticketFilterInitialValues ? 'warning' : 'light'}
                    onClick={() => {
                      setShowTicketFilterModal(true)
                    }}
                  >
                    <IonIcon slot="start" icon={filterOutline} />
                    <IonLabel>{t('Filter')}</IonLabel>
                  </IonButton>

                  {/* 「匯出」按鈕 */}
                  <IonButton
                    disabled={isProcessing}
                    color="light"
                    onClick={onExport}
                  >
                    <IonIcon slot="start" icon={downloadOutline} />
                    <IonLabel>{t('Export')}</IonLabel>
                  </IonButton>

                  {/* 搜尋欄 */}
                  <IonSearchbar
                    ref={searchbarRef}
                    type="search"
                    mode="ios"
                    value={keyword}
                    showCancelButton="focus"
                    cancelButtonText={t('Cancel')}
                    placeholder={t('Search...')}
                    inputmode={'search'}
                    onIonCancel={() => {
                      resetSearch()
                    }}
                    onIonInput={(event: any) => {
                      isPlatform('desktop') &&
                        setKeyword(event.target.value || '')
                    }}
                  ></IonSearchbar>
                  <IonButton
                    hidden={isPlatform('desktop')}
                    className="ion-padding-end"
                    color="danger"
                    size="default"
                    onClick={(event) => {
                      // @ts-ignore
                      setKeyword(searchbarRef.current.value || '')
                    }}
                  >
                    <IonLabel>{t('Search')}</IonLabel>
                  </IonButton>
                </IonItemDivider>
                {isSearchMode && (
                  <div className="ion-padding">
                    <IonList lines="inset">
                      {isProcessing && (
                        <IonItem lines="none">
                          <IonSpinner color="primary" name="dots"></IonSpinner>
                        </IonItem>
                      )}
                      {!isProcessing &&
                        !search?.isDone &&
                        !search?.error &&
                        !search.tickets?.length &&
                        showEmpty && (
                          <IonCard>
                            <IonCardHeader className="ion-text-center">
                              <IonCardTitle>
                                <IonLabel color="medium">
                                  {t('No Result')}
                                </IonLabel>
                              </IonCardTitle>
                            </IonCardHeader>
                          </IonCard>
                        )}
                      {!isProcessing &&
                        search.tickets?.map((item: ITicket, index: number) => (
                          <TicketListCard item={item} index={index} />
                        ))}
                    </IonList>

                    <div
                      className="ion-text-center"
                      hidden={isProcessing || !!search.cursor}
                    >
                      <IonButton
                        color="warning"
                        fill="clear"
                        size="large"
                        slot="icon-only"
                        onClick={scrollToTop}
                      >
                        <IonIcon icon={caretUp} />
                      </IonButton>
                    </div>
                  </div>
                )}

                {!isSearchMode && (
                  <div className="ion-padding">
                    <IonList lines="inset">
                      {isProcessing && (
                        <IonItem lines="none">
                          <IonSpinner color="primary" name="dots"></IonSpinner>
                        </IonItem>
                      )}
                      {!isProcessing &&
                        ticket?.isDone &&
                        !ticket?.error &&
                        !ticket.tickets?.length &&
                        showEmpty && (
                          <IonCard>
                            <IonCardHeader className="ion-text-center">
                              <IonCardTitle>
                                <IonLabel color="medium">
                                  {t('No Tickets')}
                                </IonLabel>
                              </IonCardTitle>
                            </IonCardHeader>
                          </IonCard>
                        )}

                      {!isProcessing &&
                        ticket.tickets?.map((item: ITicket, index: number) => (
                          <TicketListCard item={item} index={index} />
                        ))}
                    </IonList>

                    <div
                      className="ion-text-center"
                      hidden={isProcessing || !!ticket.cursor}
                    >
                      <IonButton
                        color="warning"
                        fill="clear"
                        size="large"
                        slot="icon-only"
                        onClick={scrollToTop}
                      >
                        <IonIcon icon={caretUp} />
                      </IonButton>
                    </div>
                  </div>
                )}

                <IonInfiniteScroll
                  threshold="50%"
                  onIonInfinite={loadData}
                  disabled={isSearchMode ? !search?.cursor : !ticket?.cursor}
                >
                  <IonInfiniteScrollContent></IonInfiniteScrollContent>
                </IonInfiniteScroll>
              </>
            )}
          </>
        )}

        {showTicketFilterModal && (
          <IonModal isOpen={showTicketFilterModal} backdropDismiss={false}>
            {showTicketFilterModal && (
              <TicketFilterModal
                isOpen={showTicketFilterModal}
                dismiss={dismissTicketFilterModal}
                item={ticket}
                initialValues={ticketFilterInitialValues}
              />
            )}
          </IonModal>
        )}

        {/* 列印工單 modal */}
        {showPrintTicketsModal && (
          <IonModal isOpen={showPrintTicketsModal} backdropDismiss={false}>
            {showPrintTicketsModal && (
              <PrintTicketsModal
                isOpen={showPrintTicketsModal}
                onPrint={showIonLoading}
                dismiss={dismissPrintTicketsModal}
              />
            )}
          </IonModal>
        )}
      </IonContent>
    </IonPage>
  )
}

export default TicketList
